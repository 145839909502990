@font-face {
    font-family: "rocos-icon";
    src: url("./rocos-icon.ttf?27d11a30b1084d7a73bdc197102db933") format("truetype"),
url("./rocos-icon.woff?27d11a30b1084d7a73bdc197102db933") format("woff"),
url("./rocos-icon.woff2?27d11a30b1084d7a73bdc197102db933") format("woff2"),
url("./rocos-icon.eot?27d11a30b1084d7a73bdc197102db933#iefix") format("embedded-opentype"),
url("./rocos-icon.svg?27d11a30b1084d7a73bdc197102db933#rocos-icon") format("svg");
}

[class^="ri-"]:before, [class*=" ri-"]:before {
    font-family: rocos-icon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ri-Google__G__Logo:before {
    content: "\e000";
}
.ri-Google__G__Logo-34a853:before {
    content: "\e001";
}
.ri-Google__G__Logo-4285f4:before {
    content: "\e002";
}
.ri-Google__G__Logo-ea4335:before {
    content: "\e003";
}
.ri-Google__G__Logo-fbbc05:before {
    content: "\e004";
}
.ri-automate-faded_light:before {
    content: "\e02b";
}
.ri-automate-faded_dark:before {
    content: "\e02c";
}
.ri-sendControls_light:before {
    content: "\e005";
}
.ri-sendControls_dark:before {
    content: "\e006";
}
.ri-callCommand_light:before {
    content: "\e007";
}
.ri-callCommand_dark:before {
    content: "\e008";
}
.ri-callService_light:before {
    content: "\e009";
}
.ri-callService_dark:before {
    content: "\e00a";
}
.ri-getProjectId_light:before {
    content: "\e00b";
}
.ri-getProjectId_dark:before {
    content: "\e00c";
}
.ri-getSubSystem_light:before {
    content: "\e00d";
}
.ri-getSubSystem_dark:before {
    content: "\e00e";
}
.ri-getWorkflowId_light:before {
    content: "\e00f";
}
.ri-getWorkflowId_dark:before {
    content: "\e010";
}
.ri-getCallSign_light:before {
    content: "\e011";
}
.ri-getCallSign_dark:before {
    content: "\e012";
}
.ri-subscribe_light:before {
    content: "\e013";
}
.ri-subscribe_dark:before {
    content: "\e014";
}
.ri-delay_light:before {
    content: "\e015";
}
.ri-delay_dark:before {
    content: "\e016";
}
.ri-publish_light:before {
    content: "\e017";
}
.ri-publish_dark:before {
    content: "\e018";
}
.ri-sequence_light:before {
    content: "\e019";
}
.ri-sequence_dark:before {
    content: "\e01a";
}
.ri-print_light:before {
    content: "\e01b";
}
.ri-print_dark:before {
    content: "\e01c";
}
.ri-while-loop_light:before {
    content: "\e01d";
}
.ri-while-loop_dark:before {
    content: "\e01e";
}
.ri-for-loop_light:before {
    content: "\e01f";
}
.ri-for-loop_dark:before {
    content: "\e020";
}
.ri-if_light:before {
    content: "\e021";
}
.ri-if_dark:before {
    content: "\e022";
}
.ri-goto_light:before {
    content: "\e023";
}
.ri-goto_dark:before {
    content: "\e024";
}
.ri-entry_light:before {
    content: "\e025";
}
.ri-entry_dark:before {
    content: "\e026";
}
.ri-table-header-columns_light:before {
    content: "\e027";
}
.ri-table-header-columns_dark:before {
    content: "\e028";
}
.ri-table-header-rows_light:before {
    content: "\e029";
}
.ri-table-header-rows_dark:before {
    content: "\e02a";
}
.ri-cancel:before {
    content: "\e02d";
}
.ri-arrow-down:before {
    content: "\e02e";
}
.ri-workflow-graph-1:before {
    content: "\e02f";
}
.ri-io-arrow:before {
    content: "\f191";
}
.ri-checkmark-large:before {
    content: "\f133";
}
.ri-exclamation-mark:before {
    content: "\f14e";
}
.ri-information-mark:before {
    content: "\f18e";
}
.ri-sync-file-full_blue:before {
    content: "\e030";
}
.ri-sync-file-full_green:before {
    content: "\e031";
}
.ri-sync-file-full:before {
    content: "\e032";
}
.ri-sync-file-partial_blue:before {
    content: "\e033";
}
.ri-sync-file-partial_green:before {
    content: "\e034";
}
.ri-sync-file-partial:before {
    content: "\e035";
}
.ri-sync-file:before {
    content: "\e036";
}
.ri-sync-folder-full_blue:before {
    content: "\e037";
}
.ri-sync-folder-full_green:before {
    content: "\e038";
}
.ri-sync-folder-full:before {
    content: "\e039";
}
.ri-sync-folder-partial_blue:before {
    content: "\e03a";
}
.ri-sync-folder-partial_green:before {
    content: "\e03b";
}
.ri-sync-folder-partial:before {
    content: "\e03c";
}
.ri-sync-folder:before {
    content: "\e03d";
}
.ri-data-in_light:before {
    content: "\e03e";
}
.ri-data-in_dark:before {
    content: "\e03f";
}
.ri-data-out_dark:before {
    content: "\e040";
}
.ri-data-out_light:before {
    content: "\e041";
}
.ri-graph-node_light:before {
    content: "\e042";
}
.ri-graph-node_dark:before {
    content: "\e043";
}
.ri-trigger-in_light:before {
    content: "\e044";
}
.ri-trigger-in_dark:before {
    content: "\e045";
}
.ri-trigger-out_dark:before {
    content: "\e046";
}
.ri-trigger-out_light:before {
    content: "\e047";
}
.ri-automate-faded:before {
    content: "\f101";
}
.ri-callCommand:before {
    content: "\f102";
}
.ri-callService:before {
    content: "\f103";
}
.ri-data-in:before {
    content: "\f104";
}
.ri-data-out:before {
    content: "\f105";
}
.ri-delay:before {
    content: "\f106";
}
.ri-entry:before {
    content: "\f107";
}
.ri-for-loop:before {
    content: "\f108";
}
.ri-getCallSign:before {
    content: "\f109";
}
.ri-getProjectId:before {
    content: "\f10a";
}
.ri-getSubSystem:before {
    content: "\f10b";
}
.ri-getWorkflowId:before {
    content: "\f10c";
}
.ri-goto:before {
    content: "\f10d";
}
.ri-graph-node:before {
    content: "\f10e";
}
.ri-if:before {
    content: "\f10f";
}
.ri-print:before {
    content: "\f110";
}
.ri-publish:before {
    content: "\f111";
}
.ri-sendControls:before {
    content: "\f112";
}
.ri-sequence:before {
    content: "\f113";
}
.ri-subscribe:before {
    content: "\f114";
}
.ri-trigger-in:before {
    content: "\f115";
}
.ri-trigger-out:before {
    content: "\f116";
}
.ri-while-loop:before {
    content: "\f117";
}
.ri-table-header-columns:before {
    content: "\f118";
}
.ri-table-header-rows:before {
    content: "\f119";
}
.ri-3d-box:before {
    content: "\f11a";
}
.ri-3d-ground:before {
    content: "\f11b";
}
.ri-3d-move:before {
    content: "\f11c";
}
.ri-3d-plane:before {
    content: "\f11d";
}
.ri-3d-pointcloud:before {
    content: "\f11e";
}
.ri-3d-polyhedron:before {
    content: "\f11f";
}
.ri-3d-rotate:before {
    content: "\f120";
}
.ri-3d-scale:before {
    content: "\f121";
}
.ri-3d-sphere:before {
    content: "\f122";
}
.ri-3d-torus:before {
    content: "\f123";
}
.ri-access:before {
    content: "\f124";
}
.ri-account:before {
    content: "\f125";
}
.ri-add-widget:before {
    content: "\f126";
}
.ri-add:before {
    content: "\f127";
}
.ri-alert:before {
    content: "\f128";
}
.ri-archive:before {
    content: "\f129";
}
.ri-array:before {
    content: "\f12a";
}
.ri-arrow-key-left:before {
    content: "\f12b";
}
.ri-arrow-key-right:before {
    content: "\f12c";
}
.ri-arrow-left:before {
    content: "\f12d";
}
.ri-arrow-right:before {
    content: "\f12e";
}
.ri-arrow-up:before {
    content: "\f12f";
}
.ri-audio-muted:before {
    content: "\f130";
}
.ri-audio-on:before {
    content: "\f131";
}
.ri-automate:before {
    content: "\f132";
}
.ri-bash:before {
    content: "\f134";
}
.ri-battery-100:before {
    content: "\f135";
}
.ri-battery-25:before {
    content: "\f136";
}
.ri-battery-50:before {
    content: "\f137";
}
.ri-battery-75:before {
    content: "\f138";
}
.ri-boolean:before {
    content: "\f139";
}
.ri-camera_icon:before {
    content: "\f13a";
}
.ri-camera-outline:before {
    content: "\f13b";
}
.ri-camera:before {
    content: "\f13c";
}
.ri-cancel-circle:before {
    content: "\f13d";
}
.ri-caution:before {
    content: "\f13e";
}
.ri-chat:before {
    content: "\f13f";
}
.ri-check-circle:before {
    content: "\f140";
}
.ri-checkmark:before {
    content: "\f141";
}
.ri-chevron-down:before {
    content: "\f142";
}
.ri-chevron-left:before {
    content: "\f143";
}
.ri-chevron-right:before {
    content: "\f144";
}
.ri-chevron-up:before {
    content: "\f145";
}
.ri-circle:before {
    content: "\f146";
}
.ri-clear:before {
    content: "\f147";
}
.ri-cloud-outline:before {
    content: "\f148";
}
.ri-cloud:before {
    content: "\f149";
}
.ri-connectivity:before {
    content: "\f14a";
}
.ri-control-point:before {
    content: "\f14b";
}
.ri-controller:before {
    content: "\f14c";
}
.ri-copy-link:before {
    content: "\f14d";
}
.ri-copy-to-clipboard:before {
    content: "\f14f";
}
.ri-copy:before {
    content: "\f150";
}
.ri-cube:before {
    content: "\f151";
}
.ri-d-pad:before {
    content: "\f152";
}
.ri-dashboard-filled:before {
    content: "\f153";
}
.ri-dashboard-icon:before {
    content: "\f154";
}
.ri-dashboard:before {
    content: "\f155";
}
.ri-data-explorer:before {
    content: "\f156";
}
.ri-database-up:before {
    content: "\f157";
}
.ri-delete:before {
    content: "\f158";
}
.ri-disabled-plugin:before {
    content: "\f159";
}
.ri-documentation:before {
    content: "\f15a";
}
.ri-download:before {
    content: "\f15b";
}
.ri-drag:before {
    content: "\f15c";
}
.ri-drone-deploy:before {
    content: "\f15d";
}
.ri-duplicate:before {
    content: "\f15e";
}
.ri-edit:before {
    content: "\f15f";
}
.ri-enter-fullscreen:before {
    content: "\f160";
}
.ri-exclamation-circle:before {
    content: "\f161";
}
.ri-exit-fullscreen:before {
    content: "\f162";
}
.ri-external-link:before {
    content: "\f163";
}
.ri-file-3d:before {
    content: "\f164";
}
.ri-file:before {
    content: "\f165";
}
.ri-float:before {
    content: "\f166";
}
.ri-folder:before {
    content: "\f167";
}
.ri-fullscreen:before {
    content: "\f168";
}
.ri-functions:before {
    content: "\f169";
}
.ri-gamepad-layer1:before {
    content: "\f16a";
}
.ri-gamepad-layer2:before {
    content: "\f16b";
}
.ri-global-operations:before {
    content: "\f16c";
}
.ri-hamburger:before {
    content: "\f16d";
}
.ri-heartbeat:before {
    content: "\f16e";
}
.ri-help-tip:before {
    content: "\f16f";
}
.ri-hidden-line:before {
    content: "\f170";
}
.ri-hidden:before {
    content: "\f171";
}
.ri-home:before {
    content: "\f172";
}
.ri-icon-audio:before {
    content: "\f173";
}
.ri-ig-access-denied:before {
    content: "\f174";
}
.ri-ig-add-folder:before {
    content: "\f175";
}
.ri-ig-add:before {
    content: "\f176";
}
.ri-ig-api:before {
    content: "\f177";
}
.ri-ig-bar-graph:before {
    content: "\f178";
}
.ri-ig-blank-project:before {
    content: "\f179";
}
.ri-ig-button-group:before {
    content: "\f17a";
}
.ri-ig-config:before {
    content: "\f17b";
}
.ri-ig-connect:before {
    content: "\f17c";
}
.ri-ig-delete:before {
    content: "\f17d";
}
.ri-ig-documentation:before {
    content: "\f17e";
}
.ri-ig-donut-gauge:before {
    content: "\f17f";
}
.ri-ig-drone:before {
    content: "\f180";
}
.ri-ig-duplicate-project:before {
    content: "\f181";
}
.ri-ig-hash:before {
    content: "\f182";
}
.ri-ig-help:before {
    content: "\f183";
}
.ri-ig-horiz-bar:before {
    content: "\f184";
}
.ri-ig-horizontal:before {
    content: "\f185";
}
.ri-ig-html:before {
    content: "\f186";
}
.ri-ig-image:before {
    content: "\f187";
}
.ri-ig-joystick:before {
    content: "\f188";
}
.ri-ig-line-graph:before {
    content: "\f189";
}
.ri-ig-map-pin:before {
    content: "\f18a";
}
.ri-ig-message-content:before {
    content: "\f18b";
}
.ri-ig-operations:before {
    content: "\f18c";
}
.ri-ig-plugin:before {
    content: "\f18d";
}
.ri-ig-pre-config-project:before {
    content: "\f18f";
}
.ri-ig-robots:before {
    content: "\f190";
}
.ri-ig-rover:before {
    content: "\f192";
}
.ri-ig-settings-robots:before {
    content: "\f193";
}
.ri-ig-settings-users:before {
    content: "\f194";
}
.ri-ig-settings:before {
    content: "\f195";
}
.ri-ig-table:before {
    content: "\f196";
}
.ri-ig-text:before {
    content: "\f197";
}
.ri-ig-turtlebot:before {
    content: "\f198";
}
.ri-ig-vert-bar:before {
    content: "\f199";
}
.ri-ig-vertical:before {
    content: "\f19a";
}
.ri-ig-video:before {
    content: "\f19b";
}
.ri-ig-volume-full:before {
    content: "\f19c";
}
.ri-ig-warning:before {
    content: "\f19d";
}
.ri-image-widget-heading:before {
    content: "\f19e";
}
.ri-indicator-down:before {
    content: "\f19f";
}
.ri-indicator-left:before {
    content: "\f1a0";
}
.ri-indicator-right:before {
    content: "\f1a1";
}
.ri-indicator-up:before {
    content: "\f1a2";
}
.ri-info:before {
    content: "\f1a3";
}
.ri-integer:before {
    content: "\f1a4";
}
.ri-internal-link:before {
    content: "\f1a5";
}
.ri-js-script:before {
    content: "\f1a6";
}
.ri-keyboard:before {
    content: "\f1a7";
}
.ri-laptop:before {
    content: "\f1a8";
}
.ri-latency:before {
    content: "\f1a9";
}
.ri-left-bumper:before {
    content: "\f1aa";
}
.ri-local-operations:before {
    content: "\f1ab";
}
.ri-location:before {
    content: "\f1ac";
}
.ri-locked:before {
    content: "\f1ad";
}
.ri-map-editor:before {
    content: "\f1ae";
}
.ri-map-layers:before {
    content: "\f1af";
}
.ri-map:before {
    content: "\f1b0";
}
.ri-message:before {
    content: "\f1b1";
}
.ri-minus:before {
    content: "\f1b2";
}
.ri-minus2:before {
    content: "\f1b3";
}
.ri-mission:before {
    content: "\f1b4";
}
.ri-more:before {
    content: "\f1b5";
}
.ri-move-left:before {
    content: "\f1b6";
}
.ri-move-right:before {
    content: "\f1b7";
}
.ri-nav-down:before {
    content: "\f1b8";
}
.ri-object:before {
    content: "\f1b9";
}
.ri-offline:before {
    content: "\f1ba";
}
.ri-operation:before {
    content: "\f1bb";
}
.ri-organization:before {
    content: "\f1bc";
}
.ri-pause:before {
    content: "\f1bd";
}
.ri-perception:before {
    content: "\f1be";
}
.ri-place-holder:before {
    content: "\f1bf";
}
.ri-play:before {
    content: "\f1c0";
}
.ri-play2:before {
    content: "\f1c1";
}
.ri-plugin:before {
    content: "\f1c2";
}
.ri-plus:before {
    content: "\f1c3";
}
.ri-polygon:before {
    content: "\f1c4";
}
.ri-pre-processed-3D:before {
    content: "\f1c5";
}
.ri-project-settings:before {
    content: "\f1c6";
}
.ri-project:before {
    content: "\f1c7";
}
.ri-refresh:before {
    content: "\f1c8";
}
.ri-reset-virtual-robot:before {
    content: "\f1c9";
}
.ri-right-bumper:before {
    content: "\f1ca";
}
.ri-robot-definition:before {
    content: "\f1cb";
}
.ri-robots-virtual:before {
    content: "\f1cc";
}
.ri-robots:before {
    content: "\f1cd";
}
.ri-rotate-left:before {
    content: "\f1ce";
}
.ri-rotate-right:before {
    content: "\f1cf";
}
.ri-search:before {
    content: "\f1d0";
}
.ri-sendMessage:before {
    content: "\f1d1";
}
.ri-service:before {
    content: "\f1d2";
}
.ri-settings:before {
    content: "\f1d3";
}
.ri-sign-out:before {
    content: "\f1d4";
}
.ri-square:before {
    content: "\f1d5";
}
.ri-star:before {
    content: "\f1d6";
}
.ri-storage-query:before {
    content: "\f1d7";
}
.ri-streams:before {
    content: "\f1d8";
}
.ri-string:before {
    content: "\f1d9";
}
.ri-success:before {
    content: "\f1da";
}
.ri-support:before {
    content: "\f1db";
}
.ri-switch-view:before {
    content: "\f1dc";
}
.ri-table-settings:before {
    content: "\f1dd";
}
.ri-target:before {
    content: "\f1de";
}
.ri-timer:before {
    content: "\f1df";
}
.ri-unarchive:before {
    content: "\f1e0";
}
.ri-union:before {
    content: "\f1e1";
}
.ri-unlink:before {
    content: "\f1e2";
}
.ri-value:before {
    content: "\f1e3";
}
.ri-video:before {
    content: "\f1e4";
}
.ri-view-line:before {
    content: "\f1e5";
}
.ri-view:before {
    content: "\f1e6";
}
.ri-virtual:before {
    content: "\f1e7";
}
.ri-warning:before {
    content: "\f1e8";
}
.ri-widget-builder:before {
    content: "\f1e9";
}
.ri-wifi:before {
    content: "\f1ea";
}
.ri-workflow-file:before {
    content: "\f1eb";
}
.ri-workflow-folder:before {
    content: "\f1ec";
}
.ri-workflow-graph:before {
    content: "\f1ed";
}
.ri-workflow-javascript:before {
    content: "\f1ee";
}
.ri-workflow-json:before {
    content: "\f1ef";
}
.ri-workflow-python:before {
    content: "\f1f0";
}
