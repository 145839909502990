// DD Brand colors:
$color-blue: #3f48e9;
$color-blue-dark: rgb(62, 40, 110); // $color-blue at 70% against black
$color-blue-background: #e0e2f9;
$color-blue-darker: darken($color-blue, 10%);
$color-blue-lighter: lighten($color-blue, 10%);

$color-green: #22bd66;
$color-green-dark: rgb(38, 107, 61); // $color-green at 70% against black
$color-green-background: rgb(221, 234, 225); // $color-green at 10% against white
$color-yellow: #ffcd02;
$color-yellow-background: rgb(242, 234, 219); // $color-yellow at 10% against white
$color-orange: #fd9527;
$color-orange-dark: rgb(166, 94, 37); // default at 70% against black
$color-red: #d93f3f;
$color-red-dark: rgb(139, 41, 41); // $color-red at 70% against black
$color-red-background: rgb(240, 222, 222); // $color-red at 10% against white
$color-indigo: #653fb2;
$color-indigo-dark: rgb(62, 40, 110); // $color-indigo at 70% against black

// Non DD colors:
$color-yellow-warning: #fff2c0;
$color-mid-blue: #06d8ff;
$color-markup: #fc5555;
$color-pink: #e83e8c;
$color-teal: #17a2b8;
$color-lime: #98f216;

// Rocos Greys
$color-black: #000000;
$color-midnight-blue: #11171c;
$color-coal: #192027;
$color-slate: #232b34;
$color-slate-95: rgba($color-slate, 0.95);
$color-slate-70: rgba($color-slate, 0.7);
$color-pebble-grey: #363f4b;
$color-dark-grey: #4d5560;
$color-grey: #787e86;
$color-soft-grey: #a1a5aa;
$color-mid-grey: #c8cacd;
$color-light-grey: #dadcde;
$color-fog: #edeeee;
$color-mist: #f5f6f6;
$color-white: #ffffff;

$color-transparent: transparent;


$color-success: $color-green;
$color-failure: $color-red;
$color-warning: $color-orange;

$color-toast-background-success: $color-green-background;
$color-toast-background-warning: $color-yellow-background;
$color-toast-background-danger: $color-red-background;
$color-toast-background-info: $color-blue-background;
$color-toast-text: $color-dark-grey;
$color-toast-icon-success: $color-green;
$color-toast-icon-warning: $color-yellow;
$color-toast-icon-danger: $color-red;
$color-toast-icon-info: $color-blue;
$color-toast-icon-text: $color-white;

$color-main-background: $color-mist;
$color-dark-background: $color-pebble-grey;
$color-preview-background: $color-midnight-blue;
$color-light-grey-background: $color-fog;
$color-lighter-grey-background: $color-mist;
$color-dark-widget-background: $color-dark-grey;
$color-dark-widget-text: $color-light-grey;
$color-dark-widget-second-text: $color-mid-grey;

$color-light-border: $color-light-grey;

$color-robot-status-error: $color-red;
$color-robot-status-warning: $color-orange;
$color-robot-status-offline: $color-fog;
$color-robot-status-live: $color-green;
$color-robot-status-unknown: $color-fog;

$color-robot-status-dark-error: $color-red;
$color-robot-status-dark-warning: $color-orange;
$color-robot-status-dark-offline: $color-dark-grey;
$color-robot-status-dark-live: $color-green;
$color-robot-status-dark-unknown: $color-dark-grey;

$color-description: $color-grey;

$color-hoverable-button-background: rgba($color-mid-grey, 0.075);

$color-selected-list-row-background: $color-mist;

$color-notice-background-info: rgba($color-blue, 0.1);

$color-admin-only-background: rgba($color-orange, 0.2);

$color-dashboard: (
  grid-border: rgba($color-blue, 0.3),
  grey-grid-border: $color-light-grey,
);

// For panels / widgets / cards
$color-panel: (
  background: $color-white,
  title: $color-slate,
  text: $color-slate,
  icon-button: $color-grey,
  input-background: $color-pebble-grey,
  select-background: $color-slate,
  button-background: $color-dark-grey,
  separator: $color-dark-grey,
);

$color-panel-dark: (
  background: $color-slate,
  title: $color-white,
  text: $color-fog,
  icon-button: $color-grey,
  input-background: $color-pebble-grey,
  select-background: $color-pebble-grey,
  button-background: $color-coal,
  separator: $color-coal,
);

$rocos-blue: (
  50: $color-blue,
  100: $color-blue,
  200: $color-blue,
  300: $color-blue,
  400: $color-blue,
  500: $color-blue,
  600: $color-blue,
  700: $color-blue,
  800: $color-blue,
  900: $color-blue,
  A100: $color-blue,
  A200: $color-blue,
  A400: $color-blue,
  A700: $color-blue,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);

$rocos-red: (
  50: $color-red,
  100: $color-red,
  200: $color-red,
  300: $color-red,
  400: $color-red,
  500: $color-red,
  600: $color-red,
  700: $color-red,
  800: $color-red,
  900: $color-red,
  A100: $color-red,
  A200: $color-red,
  A400: $color-red,
  A700: $color-red,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);
