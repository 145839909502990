@import 'colors';

as-split {
  .as-split-gutter {
    background-color: transparent !important;
    pointer-events: all !important;
  }
}

// Note: To create "invisible" splits when using as-split, set the gutter size
// to 1. and wrap the as-split in a div with the class "invisible-gutter".
// (class on as-split gets overwritten)
// This creates a larger mouse target for the gutter, and also sets the gutter
// color to black. Yes, this is hacky, but requires less css f**kery than the
// alternative given the constraints of as-split.

.invisible-gutter {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  as-split {
    .as-split-area {
      position: relative;
      overflow: hidden;
    }

    .as-split-gutter {
      position: relative;
      background-color: $color-black !important;
      z-index: 3 !important;

      .as-split-gutter-icon {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: none !important;
      }
    }

    &.as-horizontal {
      .as-split-gutter {
        .as-split-gutter-icon {
          height: 100% !important;
          width: 10px !important;
          top: -5px;
        }
      }
    }

    &.as-vertical {
      .as-split-gutter {
        .as-split-gutter-icon {
          width: 100% !important;
          height: 10px !important;
          left: -5px;
        }
      }
    }
  }
}
