@import 'colors.scss';
@import 'font.scss';

mat-form-field {
  width: 100%;
}

// Expand the form field hint or error message height.
// Ref: https://github.com/angular/material2/issues/5227#issuecomment-422181186
.mat-form-field {
  &.readonly {
    .mat-form-field-wrapper {
      .mat-form-field-underline {
        background-color: $color-soft-grey;
        background-image: none;
      }
    }
  }

  .mat-form-field-wrapper {
    padding-bottom: 1em;

    .mat-form-field-underline {
      position: initial !important;
      display: block;
      margin-top: -1px;
      background-color: $color-light-grey;
    }

    .mat-form-field-subscript-wrapper,
    .mat-form-field-ripple {
      position: initial !important;
      display: table;
    }

    .mat-form-field-subscript-wrapper {
      min-height: calc(1em + 1px);
    }
  }

  &.small-width {
    .mat-form-field-infix {
      width: 120px;
    }
  }
}

/**
 * Button toggle
 */
.rocos-dark-theme {
  .mat-button-toggle {
    &.mat-button-toggle-checked {
      background-color: transparent;
    }
  }
}

.mat-button-toggle {
  border: none;
  border-bottom: 2px solid transparent;

  &.mat-button-toggle-checked {
    background-color: $color-white;
    border-bottom: 2px solid $color-blue;
  }
  & + .mat-button-toggle {
    border-left: none;
    border-right: none;
  }

  .mat-button-toggle-button {
    .mat-button-toggle-label-content {
      line-height: 32px;
      font-size: 12px;
    }
  }
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: none !important;
}
.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none !important;
  border-right: none;
}

/**
 * Tabs
 */
.mat-tab-group.mat-primary .mat-tab-label {
  &.cdk-keyboard-focused,
  &.cdk-focused {
    background: none;

    &:not(.mat-tab-disabled) {
      background: none;
    }
  }
}
.mat-tab-nav-bar {
  margin-bottom: -1px;
}

// Remove the left and right span
.mat-tab-group {
  &.rs-expand-left-and-right {
    .mat-tab-header {
      margin-left: -1 * $default-spacing;
      margin-right: -1 * $default-spacing;

      padding-left: $default-spacing;
      padding-right: $default-spacing;

      border-bottom: 1px solid $color-light-border;
    }
  }
}

.rocos-dark-theme {
  .mat-tab-header {
    border-bottom: 1px solid $color-midnight-blue;
  }
}

/**
 * Toast
 */
.mat-snack-bar-container {
  .mat-simple-snackbar {
    font-size: 18px;
    margin-left: 40px;
  }
  @mixin toast(
    $background-color: $color-toast-background-success,
    $text-color: $color-toast-text,
    $icon-content: '\f133',
    $icon-color: $color-toast-icon-success
  ) {
    background-color: $background-color;
    color: $text-color;
    font-weight: $weight-bold;
    padding: $default-spacing;
    min-width: 432px;

    &::before {
      font-family: 'rocos-icon';
      background-color: $icon-color;
      content: $icon-content;
      color: $color-toast-icon-text;
      position: absolute;
      font-size: 24px;
      top: 20px;
      left: 20px;
      height: 28px;
      width: 28px;
      border-radius: 50%;
      padding-left: 2px;
      line-height: 20px;
      padding-top: 4px;
    }
  }

  &.toast-success {
    @include toast($color-toast-background-success, $color-toast-text, '\f133', $color-toast-icon-success);
  }
  &.toast-failure,
  &.toast-error {
    @include toast($color-toast-background-danger, $color-toast-text, '\f14e', $color-toast-icon-danger);
  }
  &.toast-warning {
    @include toast($color-toast-background-warning, $color-toast-text, '\f14e', $color-toast-icon-warning);
  }
  &.toast-info {
    @include toast($color-toast-background-info, $color-toast-text, '\f18e', $color-toast-icon-info);
  }
}

/**
 * Spinner
 */
button.mat-primary {
  &.mat-stroked-button {
    .mat-mdc-progress-spinner circle {
      stroke: $color-blue;
    }
  }
  .mat-mdc-progress-spinner circle {
    stroke: $color-white;
  }
  .mat-mdc-progress-spinner {
    height: 24px;
    width: 24px;
  }
}

/**
 * Checkbox
 */
.mat-mdc-checkbox {
  label {
    margin-bottom: 0;
  }
}

/**
 * Buttons
 */
.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
  font-size: 15px;
  font-weight: $weight-bold;
}

button {
  &.mat-stroked-button {
    &.cdk-focused,
    &.cdk-program-focused {
      background: none;

      .mat-button-focus-overlay {
        opacity: 0;
      }
    }

    &:disabled {
      border-color: $color-light-grey;
    }
  }
}

/**
 * Tables
 */
.mat-column-editing {
  flex: 0 0 128px;
  display: flex;
  justify-content: flex-end;
}

/**
 * Chips
 */
.mat-mdc-chip {
  &.mat-mdc-standard-chip {
    background-color: $color-blue;
    color: $color-white;
  }
}

/**
 * Modal
 */
.cdk-overlay-container.modal-open {
  transform: translate3d(0px, 0px, 1000001px);
  display: block;
  display: flex;
  display: absolute;

  .cdk-global-overlay-wrapper {
    .cdk-overlay-backdrop {
      height: 100%;
      flex: 1;
    }
    .cdk-overlay-pane {
      width: 100%;
      overflow-y: auto;
      max-width: 100% !important;

      &.bg-mist .mat-mdc-dialog-container {
        background: $color-mist !important;
      }
    }
    .mat-mdc-dialog-container {
      margin: 55px auto;
      max-width: 80vw;
      width: auto !important;
    }
    .mat-mdc-dialog-content {
      overflow: none;
      max-height: none;
    }
  }
}

/**
 * Dialog
 */
.mat-mdc-dialog-title {
  line-height: 24px;
  height: 24px;
}

/**
 * Input Field Label
 */
.mat-form-field.mat-form-field-should-float {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-form-field-label-wrapper {
          margin-top: -8px;

          label.mat-form-field-label {
            background: $color-light-grey;
            box-sizing: border-box;
            color: $color-midnight-blue;
            padding: 4px 6px 3px 6px;
            border-radius: 4px;
            width: auto;
            font-size: 16px;
          }
        }
      }
    }
  }
}

.mat-form-field .mat-form-field-flex {
  margin-top: 8px;
}

/**
 * Radio Label
 */
.mat-mdc-radio-button .mdc-form-field > label {
  margin-bottom: 0; // Remove the bottom margin added by bootstrap reset
}
