@font-face {
  font-family: 'HKGrotesk';
  src: url('HKGrotesk-Medium.eot');
  src: url('HKGrotesk-Medium.eot') format('embedded-opentype'), url('HKGrotesk-Medium.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'HKGrotesk';
  src: url('HKGrotesk-Bold.eot');
  src: url('HKGrotesk-Bold.eot') format('embedded-opentype'), url('HKGrotesk-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url(https://fonts.googleapis.com/css?family=Lato:400,100,100italic,300,300italic,400italic,700,700italic,900,900italic);
}
