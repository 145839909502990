/* these styles are for overriding styles for the custom operation view */
.custom-operations .navbar {
  display: none !important;
}
.custom-operations .sidebar {
  display: none !important;
}
.custom-operations .content {
  overflow: visible !important;
  height: 100% !important;
}
.custom-operations .content .main {
  overflow: visible !important;
  height: 100% !important;
}
.custom-operations .main-content {
  height: 100% !important;
}
