@import 'colors';

/* Important! The bounding box must have no 
 * line-height for the icont to be centered  
*/
[class^='ri-'],
[class*=' ri-'] {
  line-height: 0;
}

[class^='ri-'] > span:before,
[class*=' ri-'] > span:before {
  font-family: rocos-icon !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^='ri-'] > span:not(:first-child):before,
[class*=' ri-'] > span:not(:first-child):before {
  margin-left: -1em;
}

/* Google logo elements, combined  */

.ri-Google__G__Logo {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e001';
      color: #34a853;
    }
    &:nth-child(2):before {
      content: '\e002';
      color: #4285f4;
    }
    &:nth-child(3):before {
      content: '\e003';
      color: #ea4335;
    }
    &:nth-child(4):before {
      content: '\e004';
      color: #fbbc05;
    }
  }
}

/* Flow icons, combined  */

.ri-automate-faded {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e02b';
      color: $color-green;
      opacity: 0.4;
    }
    &:nth-child(2):before {
      content: '\e02c';
      color: $color-green;
    }
  }
}

.ri-sendControls {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e005';
      color: $color-green;
      opacity: 0.4;
    }
    &:nth-child(2):before {
      content: '\e006';
      color: $color-green;
    }
  }
}

.ri-callCommand {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e007';
      color: $color-green;
      opacity: 0.4;
    }
    &:nth-child(2):before {
      content: '\e008';
      color: $color-green;
    }
  }
}

.ri-callService {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e009';
      color: $color-green;
      opacity: 0.4;
    }
    &:nth-child(2):before {
      content: '\e00a';
      color: $color-green;
    }
  }
}

.ri-getProjectId {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e00b';
      color: $color-green;
      opacity: 0.4;
    }
    &:nth-child(2):before {
      content: '\e00c';
      color: $color-green;
    }
  }
}

.ri-getSubSystem {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e00d';
      color: $color-green;
      opacity: 0.4;
    }
    &:nth-child(2):before {
      content: '\e00e';
      color: $color-green;
    }
  }
}

.ri-getWorkflowId {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e00f';
      color: $color-green;
      opacity: 0.4;
    }
    &:nth-child(2):before {
      content: '\e010';
      color: $color-green;
    }
  }
}

.ri-getCallSign {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e011';
      color: $color-green;
      opacity: 0.4;
    }
    &:nth-child(2):before {
      content: '\e012';
      color: $color-green;
    }
  }
}

.ri-subscribe {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e013';
      color: $color-green;
      opacity: 0.4;
    }
    &:nth-child(2):before {
      content: '\e014';
      color: $color-green;
    }
  }
}

.ri-delay {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e015';
      color: $color-green;
      opacity: 0.4;
    }
    &:nth-child(2):before {
      content: '\e016';
      color: $color-green;
    }
  }
}

.ri-publish {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e017';
      color: $color-green;
      opacity: 0.4;
    }
    &:nth-child(2):before {
      content: '\e018';
      color: $color-green;
    }
  }
}

.ri-sequence {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e019';
      color: $color-green;
      opacity: 0.4;
    }
    &:nth-child(2):before {
      content: '\e01a';
      color: $color-green;
    }
  }
}

.ri-print {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e01b';
      color: $color-green;
      opacity: 0.4;
    }
    &:nth-child(2):before {
      content: '\e01c';
      color: $color-green;
    }
  }
}

.ri-while-loop {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e01d';
      color: $color-green;
      opacity: 0.4;
    }
    &:nth-child(2):before {
      content: '\e01e';
      color: $color-green;
    }
  }
}

.ri-while-loop {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e01d';
      color: $color-green;
      opacity: 0.4;
    }
    &:nth-child(2):before {
      content: '\e01e';
      color: $color-green;
    }
  }
}

.ri-for-loop {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e01f';
      color: $color-green;
      opacity: 0.4;
    }
    &:nth-child(2):before {
      content: '\e020';
      color: $color-green;
    }
  }
}

.ri-if {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e021';
      color: $color-green;
      opacity: 0.4;
    }
    &:nth-child(2):before {
      content: '\e022';
      color: $color-green;
    }
  }
}

.ri-goto {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e023';
      color: $color-green;
      opacity: 0.4;
    }
    &:nth-child(2):before {
      content: '\e024';
      color: $color-green;
    }
  }
}

.ri-entry {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e025';
      color: $color-green;
      opacity: 0.4;
    }
    &:nth-child(2):before {
      content: '\e026';
      color: $color-green;
    }
  }
}

/* table icons */

.ri-table-header-columns {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e027';
      color: $color-black;
      opacity: 0.4;
    }
    &:nth-child(2):before {
      content: '\e028';
      color: $color-black;
    }
  }
}

.ri-table-header-rows {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e029';
      color: $color-black;
      opacity: 0.4;
    }
    &:nth-child(2):before {
      content: '\e02a';
      color: $color-black;
    }
  }
}

/* sync icons */

.ri-sync-file {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e036';
      color: $color-blue;
    }
  }
}

.ri-sync-file-full {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e031';
      color: $color-green;
    }
    &:nth-child(2):before {
      content: '\e030';
      color: $color-blue;
    }
  }
}

.ri-sync-file-partial {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e034';
      color: $color-green;
    }
    &:nth-child(2):before {
      content: '\e033';
      color: $color-blue;
    }
  }
}

.ri-sync-file-partial {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e034';
      color: $color-green;
    }
    &:nth-child(2):before {
      content: '\e033';
      color: $color-blue;
    }
  }
}

.ri-sync-folder {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e03d';
      color: $color-blue;
    }
  }
}

.ri-sync-folder-full {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e038';
      color: $color-green;
    }
    &:nth-child(2):before {
      content: '\e037';
      color: $color-blue;
    }
  }
}

.ri-sync-folder-partial {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e03b';
      color: $color-green;
    }
    &:nth-child(2):before {
      content: '\e03a';
      color: $color-blue;
    }
  }
}

/* Additional Flow Icons */

.ri-data-in {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e03e';
      color: $color-green;
      opacity: 0.4;
    }
    &:nth-child(2):before {
      content: '\e03f';
      color: $color-green;
    }
  }
}

.ri-data-out {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e040';
      color: $color-green;
    }
    &:nth-child(2):before {
      content: '\e041';
      opacity: 0.4;
      color: $color-green;
    }
  }
}

.ri-graph-node {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e042';
      color: $color-green;
      opacity: 0.4;
    }
    &:nth-child(2):before {
      content: '\e043';
      color: $color-green;
    }
  }
}

.ri-trigger-in {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e044';
      color: $color-green;
      opacity: 0.4;
    }
    &:nth-child(2):before {
      content: '\e045';
      color: $color-green;
    }
  }
}

.ri-trigger-out {
  &:before {
    content: '';
  }
  > span {
    &:nth-child(1):before {
      content: '\e047';
      opacity: 0.4;
      color: $color-green;
    }
    &:nth-child(2):before {
      content: '\e046';
      color: $color-green;
    }
  }
}
