@import '../node_modules/bootstrap/scss/bootstrap';
@import '../node_modules/xterm/css/xterm.css';


@import 'styles/colors';
@import 'styles/variables';
@import 'styles/theme.scss';
@import 'styles/material';
@import 'styles/customized';
@import 'styles/font';
@import 'styles/fonts/hk-grotesk/font.css';
@import 'styles/fonts/rocos-icon/rocos-icon.css';
@import 'styles/fonts/rocos-icon-colors.scss';
@import 'styles/custom-operation-view.css';
@import 'styles/as_split';

body {
  color: $color-slate;
  letter-spacing: 0;
}
html,
body {
  height: 100%;
  font-family: $font-main;
  font-size: $default-font-size;
}
button,
button:focus {
  outline: none;
  box-shadow: none;
}
a {
  &,
  &:hover,
  &:active {
    text-decoration: none;
  }
}

.clickEffect {
  position: absolute;
  border-style: solid;
  border-color: #ffffff;
  border-radius: 50%;
  animation: clickEffect 0.3s ease-out;
  z-index: 99999;
  width: 10px;
  height: 10px;
  border-width: 2px;
  opacity: 1;
}

@keyframes clickEffect {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}

.xterm-viewport {
  overflow: hidden !important;
}