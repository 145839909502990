@use '@angular/material' as mat;

@import 'colors.scss';
@import 'font';

// Plus imports for other components in your app.

$custom-typography: mat.define-typography-config(
  $font-family: #{$font-main},
);
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.all-component-typographies($custom-typography);
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
@include mat.legacy-core();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette($rocos-blue);
$app-accent: mat.define-palette($rocos-blue);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette($rocos-red);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat.define-light-theme($app-primary, $app-accent, $app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
/* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
@include mat.all-legacy-component-themes($app-theme);
@include mat.all-component-themes($app-theme);

// DARK THEME
$dark-primary: mat.define-palette($rocos-blue);
$dark-accent: mat.define-palette($rocos-blue);
$dark-warn: mat.define-palette($rocos-red);

$dark-theme: mat.define-dark-theme($dark-primary, $dark-accent, $dark-warn);
.rocos-dark-theme {
  /* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
  @include mat.all-legacy-component-themes($dark-theme);
  @include mat.all-component-themes($dark-theme);
}
