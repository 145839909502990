$default-border-radius: 4px;
$controls-border-radius: 2px;
$default-spacing: 24px;
$default-page-padding: 24px;

$z-index-donut-gauge-content: 5;
$z-index-widget-group-def-warning-layer: 8;
$z-index-widget-status-layer: 10;
$z-index-widget-edit-layer: 11;
$z-index-widget-gridster-resize-handler: 12;
$z-index-widget-edit-buttons: 13;

$z-index-ops-properties-panel: 15;
$tab-letter-spacing: 0.4px;
$default-font-size: 14px;
$button-group-border-radius: 8px;

$debug-mode: false;

$font-sizes: (
  widget-preview-box-title: 16px,
);
