@import 'colors.scss';
@import 'mixin';
@import 'font';
@import 'variables';

/**
 * Rocos icons
 */
[class^='ri-'],
[class*=' ri-'] {
  font-size: 24px;
  vertical-align: middle;
}

[class^='ri-ig-'],
[class*=' ri-ig-'] {
  font-size: 64px;
}

.rs-items-align-middle {
  & > * {
    vertical-align: middle;
  }
}

.rs-head-title {
  font-size: 20px;
}

.rs-head-subtitle {
  font-size: 18px;
}

.rs-grey-icon {
  color: $color-grey;
}

.rs-icon-button-padding {
  padding: 8px;
}

.rs-input-field-description {
  font-size: 11px;
  color: $color-description;
  line-height: 24px;
}

.rs-head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 20px 24px;
  min-height: 64px;

  &.no-padding {
    padding: 0;
  }

  &.no-bottom-padding {
    padding-bottom: 0;
  }

  .title {
    font-size: 20px;
  }

  .subtitle {
    font-size: 14px;
  }

  .right-buttons {
    button {
      & + button {
        margin-left: $default-spacing * 0.5;
      }
    }
  }
}

.rs-card {
  border-radius: 4px;
  background-color: $color-white;

  .head {
    padding: 20px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: inherit;

    .title-block {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      .subtitle {
        font-size: 0.8rem;
        color: $color-grey;
      }
    }

    .title {
      font-size: 18px;
      line-height: 1;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.with-spinner {
        min-height: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }

    .right-buttons {
      margin: -12px 0;

      display: flex;
      flex-direction: row;
    }
  }

  table,
  mat-table {
    width: 100%;

    .clickable-row {
      @include clickable();
      @include hoverable();
    }
    .selected-row {
      &,
      &.clickable-row {
        background-color: $color-selected-list-row-background;
      }
    }
  }

  .body {
    padding: 0 24px 24px 24px;

    &.no-padding {
      padding: 0;
    }

    table {
      width: 100%;
    }
  }

  .row-buttons {
    display: flex;
    align-items: center;
    color: $color-grey;
  }
}

.rocos-dark-theme {
  font-family: $font-main;

  color: $color-white;

  mat-select {
    border-radius: $controls-border-radius;
    background-color: map-get($color-panel-dark, 'select-background');

    .mat-select-value {
      padding: 2px 8px;
    }
  }

  input {
    border: none;
    color: map-get($color-panel-dark, 'text');
    border-radius: $controls-border-radius;
    padding: 2px 8px;
    background-color: map-get($color-panel-dark, 'input-background');
  }
}

.color-primary {
  color: $color-blue;
}

a.mat-button {
  color: inherit;
  text-decoration: none !important;
}

.mat-tab-group {
  &.wider-padding {
    .mat-tab-labels {
      .mat-tab-label {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }

  &.higher-padding {
    .mat-tab-labels {
      .mat-tab-label {
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }
  }

  &.no-bottom-border {
    .mat-tab-header {
      border-bottom: none;
    }
  }
}

.mat-tab-labels {
  .mat-tab-label {
    height: inherit;
    min-width: 0;
    padding: 12px 16px;
    letter-spacing: 0.4px;
    line-height: 14px;
  }
}

.mat-tab-label {
  &.mat-tab-label-active {
    color: $color-blue;
    opacity: 1;
  }
}

a.mat-tab-link {
  height: inherit;
  min-width: 0;

  padding: 12px 16px;

  letter-spacing: $tab-letter-spacing;
  line-height: 14px;
}

a.mat-tab-link {
  &.active-tab,
  &.mat-tab-label-active {
    color: $color-blue;
    opacity: 1;
  }
}

// Project picker max-width
.mat-menu-panel.projects-menu {
  max-width: 424px;
  min-width: 424px;
  max-height: 600px;
}

button,
a {
  &.left-icon-button {
    padding: 0 12px 0 8px;

    [class^='ri-'] {
      margin-right: 8px;
    }

    .icon {
      margin: 0;
    }
  }

  &.right-icon-button {
    padding: 0 8px 0 16px;

    .icon {
      margin: 0;
    }
  }
}

button,
a {
  &.mat-stroked-button {
    padding: 0 12px;

    &.compact-icon {
      padding: 0 12px 1.5px 0;
    }

    span[class^='ri-'] {
      margin-right: 8px;
      margin-left: -4px;
    }

    &.right-icon-button {
      span[class^='ri-'] {
        margin-left: 8px;
        margin-right: -4px;
      }
    }
  }
}

/******************
 * pre
 */
pre {
  border-radius: 4px;
  padding: 16px;
  color: $color-white;
  background: $color-pebble-grey;

  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

/************
 * Dialog
 */
.rs-dialog, .dialog {
  margin: 24px;

  .mat-mdc-dialog-title {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 11px;

    &::before {
      content: none;
    }
  }
}

.rs-dialog {
  width: 680px;

  &.file-explorer-ui {
    min-width: 1024px;
  }

  &.narrow {
    width: 300px;
  }

  &.medium {
    width: 560px;
  }

  &.large {
    width: 720px;
  }

  &.x-large {
    width: 1024px;
  }

  &.maximum {
    width: 70vw;
  }

  &.data-explorer-ui {
    width: 70vw;

    background-color: $color-light-grey-background;

    padding: $default-spacing;
    margin: 0;
  }

  h2.title,
  h2.mat-mdc-dialog-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .left-part {
      display: flex;
      flex-direction: row;
      align-items: center;

      .back-btn {
        color: $color-grey;
      }
    }

    .right-buttons {
      margin-right: -8px;
    }
  }

  .selection-list {
    margin-top: $default-spacing;

    .item {
      border: 2px solid $color-light-border;
      border-radius: $default-border-radius;

      @include clickable();
      @include hoverable();

      padding: 12px;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      transition: border-color ease-in-out 0.2s;

      &:hover,
      &.selected {
        border-color: $color-blue;
        box-shadow: 0 0 6px 0 rgba(12, 141, 255, 0.3);
      }

      & + .item {
        margin-top: 16px;
      }

      .left {
        display: flex;
        flex-direction: row;
        align-items: center;

        .icon {
          color: $color-blue;
        }

        .text {
          margin-left: 1rem;

          .title {
            font-size: 18px;
            line-height: 21px;
          }

          .description {
            margin-top: 4px;

            color: $color-description;
            font-size: 12px;
            line-height: 14px;
          }
        }
      }

      .right {
        padding-right: 20px;

        .logo {
          img {
            height: 24px;
          }
        }
      }
    }
  }

  .group {
    .title {
      font-size: 18px;
      margin-top: 24px;
      margin-bottom: 12px;

      .reset-button {
        margin-left: 12px;
      }
    }
  }

  .footer {
    margin-top: 24px;
    text-align: right;

    &.tight {
      margin-top: 12px;
      margin-bottom: -12px;

      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

/******************
 * Notice
 */
.rs-notice {
  padding: 32px;
  background-color: $color-light-grey-background;
  border-radius: $default-border-radius;

  transition: all ease 0.5s, opacity ease 0.1s;

  &.bottom-margin {
    margin-bottom: $default-spacing;
  }

  &.inline {
    padding: 8px 16px;
    margin-bottom: 0;
  }

  &.permanent-info {
    background-color: #fff2c0;
  }

  &.top-left-right-margin {
    margin-top: $default-spacing;
    margin-left: $default-spacing;
    margin-right: $default-spacing;
  }
  &.sticky-left {
    position: sticky;
    left: 24px;
  }

  &.warning-box {
    border: 1px solid $color-toast-icon-warning;
  }

  &.error-box {
    background-color: #fcdfdf;
  }

  .warning-icon {
    color: white;
    background-color: $color-toast-icon-warning;
    border: 1px solid $color-toast-icon-warning;
    border-radius: 14px;
    margin-right: 15px;
  }

  .error-icon {
    color: white;
    background-color: $color-toast-icon-danger;
    border: 1px solid $color-toast-icon-danger;
    border-radius: 14px;
    margin-right: 15px;
  }

  &.closing {
    height: 0;
    padding: 0;
    margin: 0;

    opacity: 0;

    .title,
    .desc,
    .actions {
      height: 0;
      opacity: 0;
    }
  }

  &.lighter-background {
    background-color: $color-lighter-grey-background;
  }

  .title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 8px;
    font-weight: $weight-normal;
  }

  .permanent {
    font-size: 14px;
    line-height: 22px;
  }

  .desc {
    font-size: 15px;
    line-height: 20px;
  }

  .actions {
    margin-top: 24px;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    .link-content {
      display: flex;
      align-items: center;
    }
  }
}

.dashboard-page {
  .rs-notice {
    background-color: $color-slate-95;
    color: white;
    &.top-left-right-margin {
      margin-top: 0;
      margin-left: 1px;
      margin-bottom: 1px;
      margin-right: 0;
    }
  }
}

.rs-message-box {
  font-size: 15px;
  border-radius: $default-border-radius;
  padding: $default-spacing * 0.5;
  background-color: $color-light-grey-background;

  &.info {
    background-color: $color-notice-background-info;
  }
}

/**
 * Row and Column
 */
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  padding-left: $default-spacing * 0.5;
  padding-right: $default-spacing * 0.5;
}

.row {
  margin-left: -$default-spacing * 0.5;
  margin-right: -$default-spacing * 0.5;
}

/******************
 * TOOLTIP
 */
.mat-mdc-tooltip,
.rocos-dark-theme .mat-tooltip {
  border-radius: 4px;
  background-color: transparentize($color: $color-dark-grey, $amount: 0.1);

  &.rs-text-tooltip {
    word-break: break-all;
  }

  &.pre-tooltip {
    white-space: pre-line;
  }
}

.rs-loading-spinner-content {
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon {
    margin-left: -4px;
    margin-right: 4px;
  }

  opacity: 1;

  transition: opacity 0.2s ease-in-out;

  &.loading {
    opacity: 0;
  }
}

.rs-left-button-icon {
  margin-left: -4px;
}

/*********************
 * CHECKBOX
 */
mat-checkbox {
  margin-left: calc(calc((var(--mdc-checkbox-state-layer-size) - 18px) / 2) * -1)
}

/*********************
 * BUTTON
 */
.rs-inline-button {
  margin-right: 12px !important;

  &.mat-icon-button {
    margin-right: 0;

    & + .mat-flat-button {
      margin-left: 12px;
    }
  }

  &:last-child {
    margin-right: 0 !important;
  }
}

.rs-tiny-icon-button {
  &.mat-icon-button {
    line-height: 24px;
    &.stream-control-button {
      line-height: 18px;
    }
    width: auto;
    height: auto;
  }
  &.mat-button,
  &.mat-flat-button {
    line-height: 24px;
    min-width: 24px;
    border-radius: 2px;
    padding: 0;
  }
}

.rs-link-button {
  font-size: 15px;
  font-weight: $weight-bold;
  background-color: transparent;
  border: none;
  color: $color-blue;
  padding: 0;
  margin: 0;

  & > * {
    vertical-align: middle;
  }

  &.left-icon-button,
  &.right-icon-button {
    padding: 0;
  }

  &:hover {
    color: $color-blue-darker;
  }
  &:focus {
    color: $color-blue-darker;
  }
}

/********************
 * WIDGET
 */

$color-highlight-border: rgba($color-success, 0.3);
$color-selected-text: $color-mist;
$color-normal-text: $color-soft-grey;

.rs-widget {
  border-radius: $controls-border-radius;

  & > .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 15px;
      padding: 12px;
      font-weight: $weight-bold;
    }
  }

  .body {
    padding: 0 16px 12px;

    .list {
      .item {
        @include hoverable();
        @include clickable();

        border: 1px solid transparent;
        border-radius: $default-border-radius;
        color: $color-normal-text;

        padding: 8px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        &.selected {
          border-color: $color-highlight-border;
          background-color: $color-hoverable-button-background;
          color: $color-selected-text;
        }

        .item-left-icon {
          margin-right: 8px;
        }
      }
    }

    .no-items-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: $default-spacing * 0.5;

      .no-items-message {
        color: $color-light-grey;
      }
    }
  }

  &.rocos-dark-theme {
    background-color: map-get($color-panel-dark, background);

    .heading {
      color: map-get($color-panel-dark, title);

      .right-buttons {
        color: map-get($color-panel-dark, icon-button);
      }
    }

    .body {
      color: map-get($color-panel-dark, text);
    }
  }
}

/**
 * Copy
 */
.rs-copy-code-wrapper {
  position: relative;

  &.display-button {
    .copy-code-button {
      opacity: 1;
    }
  }

  &:hover {
    .copy-code-button {
      opacity: 1;
    }
  }

  .copy-code-button {
    position: absolute;
    opacity: 0;

    top: 12px;
    right: 12px;

    transition: opacity 0.2s ease-in-out;
  }
}

.rs-warning {
  background-color: $color-toast-background-warning;

  padding: 16px;
  margin-bottom: 24px;
  font-size: 16px;
  display: flex;
  flex-direction: row;

  .warning-text {
    margin-top: 2px;
  }

  .warning-icon {
    margin-right: 16px;

    .background-dot {
      height: 28px;
      width: 28px;
      border-radius: 50%;
      padding-left: 2px;
      line-height: 20px;
      padding-top: 3px;
      display: block;

      color: $color-white;
      background-color: $color-toast-icon-warning;
    }
  }
}

.command-with-value-button .mat-flat-button.normal {
  background-color: transparent;
}

/**
 * Gridster
 */

gridster.rs-gridster {
  background-color: transparent;

  .gridster-row {
    border-bottom-color: map-get($color-dashboard, 'grid-border');
    border-top-color: map-get($color-dashboard, 'grid-border');
  }
  .gridster-column {
    border-left-color: map-get($color-dashboard, 'grid-border');
    border-right-color: map-get($color-dashboard, 'grid-border');
  }

  &.grey-border {
    .gridster-row {
      border-bottom-color: map-get($color-dashboard, 'grey-grid-border');
      border-top-color: map-get($color-dashboard, 'grey-grid-border');
    }
    .gridster-column {
      border-left-color: map-get($color-dashboard, 'grey-grid-border');
      border-right-color: map-get($color-dashboard, 'grey-grid-border');
    }
  }

  .gridster-item-resizable-handler {
    $handler-border-width: 10px;

    z-index: $z-index-widget-gridster-resize-handler;
    // East
    &.handle-e {
      width: $handler-border-width;
    }
    // West
    &.handle-w {
      width: $handler-border-width;
    }
    // North
    &.handle-n {
      height: $handler-border-width;
    }
    // South
    &.handle-s {
      height: $handler-border-width;
    }

    // Corners
    &.handle-se,
    &.handle-ne,
    &.handle-sw,
    &.handle-nw {
      height: $handler-border-width;
      width: $handler-border-width;
    }

    &.handle-se {
      border-width: 0;
    }
  }

  gridster-item {
    background-color: transparent;
    border-radius: 4px;

    &.gridster-item-moving {
      box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.5);
    }
  }

  // overrides for button group as the dashboard element is really narrow
  gridster-item.button-group {
    .gridster-item-resizable-handler {
      $handler-border-width: 5px;

      z-index: $z-index-widget-gridster-resize-handler;
      // East
      &.handle-e {
        width: $handler-border-width;
      }
      // West
      &.handle-w {
        width: $handler-border-width;
      }
      // North
      &.handle-n {
        height: $handler-border-width;
      }
      // South
      &.handle-s {
        height: $handler-border-width;
      }

      // Corners
      &.handle-se,
      &.handle-ne,
      &.handle-sw,
      &.handle-nw {
        height: $handler-border-width;
        width: $handler-border-width;
      }

      &.handle-se {
        border-width: 0;
      }
    }
  }

  gridster-preview {
    border-radius: 4px;
    box-shadow: inset 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  }
}

/**
 * Links
 */
a {
  color: $color-blue;
}

.add-event-dialog {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .mat-mdc-dialog-container::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .mat-mdc-dialog-container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

.rs-default-page-padding {
  padding: $default-page-padding;
}

$directions: top, bottom, left, right;

@each $dir in $directions {
  .rs-default-margin-#{$dir} {
    margin-#{$dir}: $default-spacing;
  }
  .rs-default-padding-#{$dir} {
    padding-#{$dir}: $default-spacing;
  }
}

.rs-primary-background {
  button {
    &.primary-button {
      border-color: $color-white;
      background-color: $color-white;
      color: $color-blue;
    }
    &.secondary-button {
      color: $color-white;
      border-color: transparentize($color: $color-white, $amount: 0.5);
    }
  }
}

// custom mapbox gl style

.mapboxgl-ctrl-bottom-left {
  z-index: 0;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  background-color: transparent !important;
  display: flex;
  top: 42px !important;
  position: relative;
  padding: 0 8px !important;
  white-space: nowrap;
}

.mapboxgl-ctrl-attrib-button {
  display: none !important;
}

.mapboxgl-ctrl-logo {
  margin: 0 0 14px -3px !important;
}
