@import 'colors.scss';

@mixin clickable() {
  user-select: none;
  cursor: pointer;
  outline: none;
}

@mixin hoverable() {
  &:hover {
    background-color: $color-hoverable-button-background;
  }
}

@mixin no-scrollbar() {
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin scrollbar() {
  --scrollbar-foreground: #787e86;
  --scrollbar-background: #192027;
  scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);

  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-foreground);
    border-radius: 3px;
  }
  &::-webkit-scrollbar-track {
    background: var(--scrollbar-background);
  }
}

@mixin scrollbar-minimal() {
  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: $color-dark-grey;
  }
}

@mixin col-with-gap($gap: 12px){
  display: flex;
  flex-direction: column;
  gap: $gap;
}